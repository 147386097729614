import { useState } from 'react';
import Fade from '@mui/material/Fade';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab, TabsGroup } from '../../components/tabs';
import { LinkNavigate, Tables } from '../../shared/ui';
import { PopupItemWrap, StyledBoxShadow, Content, StyledCircle, LI, UL, LIW } from './ui';
import file from '../../assets/img/file.svg';
import mainImg from './img/main.png';
import lac1 from './img/lac-1.png';
import lac2 from './img/lac-2.png';
import lac3 from './img/lac-3.png';
import lac4 from './img/lac-4.png';
import lac5 from './img/lac-5.png';

import lacFarm1 from './img/lac-farm1.png';
import lacFarm2 from './img/lac-farm2.png';
import lacFarm3 from './img/lac-farm3.png';
import lacFarm4 from './img/lac-farm4.png';
import lacFarm5 from './img/lac-farm5.png';

import lacNapkin1 from './img/lac-napkin1.png';
import lacNapkin2 from './img/lac-napkin2.png';
import lacNapkin3 from './img/lac-napkin3.png';
import lacNapkin4 from './img/lac-napkin4.png';

import lacImg1 from './img/lac-img1.png';
import lacImg2 from './img/lac-img2.png';
import lacImg3 from './img/lac-img3.png';
import lacImg4 from './img/lac-img4.png';
import lacImg5 from './img/lac-img5.png';
import lacImg6 from './img/lac-img6.png';
import lacImg7 from './img/lac-img7.png';
import lacImg8 from './img/lac-img8.png';
import lacImg9 from './img/lac-img9.png';
import lacImg10 from './img/lac-img10.png';
import lacImg11 from './img/lac-img11.png';
import lacImg12 from './img/lac-img12.png';
import lacImg13 from './img/lac-img13.png';

import lacLine1 from './img/lac-line1.png';
import lacLine2 from './img/lac-line2.png';
import lacLine3 from './img/lac-line3.png';

import imgEveryday from './img/lac-everyday.png';
import imgSkin from './img/lac-skin.png';
import imgImmun from './img/lac-immun.png';
import imgDry from './img/lca-dry.png';
import imgBactery from './img/lac-bactery.png';
import imgStandart from './img/lac-standart.png';


import Popup from 'shared/ui/Popup';
import { LactacydPharma } from './lactacyd-materials';
import { Box } from '@mui/material';
import Page from 'shared/ui/Page';
import { Black, PinkLight } from '../../styles/color';
import PrezentationItem from '../../components/Product/PrezentationItem';

export const Lactacyd = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const handleRedirectBack = () => {
    const url = pathname === '/product/lactacyd/pharma' ? '/product/lactacyd' : '/product';
    history.push(url);
  };

  const handleRedirect = () => {
    setIsOpen(false);
    history.push('/product/lactacyd/pharma');
  };

  const backTextBtn = pathname === '/product/lactacyd/pharma' ? 'Назад' : 'Продукты';
  return (
    <>
      <Page head={'ЛАКТАЦИД'} backText={backTextBtn} onClose={handleRedirectBack}>
        <TabsGroup>
          <Tab onClick={() => setIsOpen(true)}>
            Материалы
          </Tab>
          <Tab>
            <LinkNavigate to="/video/Видео/F4wxbyyM9QY">Видео</LinkNavigate>
          </Tab>
        </TabsGroup>

        {
          pathname === '/product/lactacyd/pharma' ? <LactacydPharma /> : (

            <Fade in={true} timeout={1100}>
              <Content>
                <img style={{ maxWidth: '100%', marginTop: 5 }} src={mainImg} alt="lactacyd" />
                <Box sx={{ fontSize: 38, fontWeight: 600, textAlign: 'center', margin: '20px auto 0 px' }}>
                  LACTACYD: самая широкая линия средств — 14 SKU
                </Box>
                <StyledBoxShadow>
                  <Box className="head" sx={{ marginTop: '20px' }}>СРЕДСТВА ЛАКТАЦИД</Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px', marginTop: '36px' }}>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 5.2</Box>
                      <img src={lac1} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>базовый
                        уход</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 5.2</Box>
                      <img src={lac2} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>нежный
                        мусс</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 4.7</Box>
                      <img src={lac3} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>для девочек с
                        3-х
                        лет</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 4.7</Box>
                      <img src={lac4} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>до 12 часов
                        свежести</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 4.7</Box>
                      <img src={lac5} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>до 8 часов
                        увлажнения и мягкости</Box>
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow>
                  <Box className="head" sx={{ marginTop: '20px' }}>LACTACYD PHARMA</Box>
                  <Box className="sub-head">
                    с более низким рН 3.5/более высоким рН 8.0 — для применения в разные периоды жизни
                  </Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px', marginTop: '36px' }}>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 3.5</Box>
                      <img src={lacFarm1} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>для
                        сверх-чувствительной кожи</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 3.5</Box>
                      <img src={lacFarm2} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>для
                        анти-бактериальной защиты</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 3.5</Box>
                      <img src={lacFarm3} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>глубокое
                        увлажнение и питание</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 3.5</Box>
                      <img src={lacFarm4} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>для устранения
                        дискомфорта при лечении вагинальных инфекций</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="badge" sx={{ margin: '20px auto' }}>pH 8.0</Box>
                      <img src={lacFarm5} alt="lactacyd" />
                      <Box className="sub-head border-top" sx={{ paddingTop: '16px', marginTop: '12px' }}>для устранения
                        дискомфорта при лечении молочницы</Box>
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow>
                  <Box className="head" sx={{ marginTop: '20px' }}>LACTACYD САЛФЕТКИ</Box>
                  <Box className="sub-head">для свежести и комфорта в любой ситуации</Box>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px', marginTop: '36px', marginBottom: '28px' }}>
                    <Box sx={{ flex: '1 1 0' }}>
                      <img src={lacNapkin1} alt="lactacyd" />
                      <Box className="sub-head border-top"
                           sx={{ paddingTop: '16px', marginTop: '12px' }}>LACTACYD <br /> CLASSIC,<br /> 8 шт.</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <img src={lacNapkin2} alt="lactacyd" />
                      <Box className="sub-head border-top"
                           sx={{ paddingTop: '16px', marginTop: '12px' }}>LACTACYD <br />CLASSIC,<br /> 15
                        шт.</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <img src={lacNapkin3} alt="lactacyd" />
                      <Box className="sub-head border-top"
                           sx={{ paddingTop: '16px', marginTop: '12px' }}>LACTACYD<br /> PHARMA,<br /> 8 шт.</Box>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <img src={lacNapkin4} alt="lactacyd" />
                      <Box className="sub-head border-top"
                           sx={{ paddingTop: '16px', marginTop: '12px' }}>LACTACYD <br />PHARMA,<br /> 15
                        шт.</Box>
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow>
                  <Box className="head" sx={{ marginTop: '20px' }}>Важность рН баланса в интимной зоне</Box>
                  <Box sx={{ display: 'flex', gap: '20px', margin: '40px 0px', flexWrap: 'wrap' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <StyledCircle>1</StyledCircle>
                      <p>
                        Нормальный рН баланс интимной
                        <span style={{ color: '#F9D2D6' }}>Это кислая среда</span>

                      </p>
                    </Box>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <StyledCircle>2</StyledCircle>
                      <p>
                        <span style={{ color: '#F9D2D6' }}>Правильный рН-баланс (кислая среда)</span> способствует
                        оптимальному соотношению «хороших» лактобактерий и «плохих» — условно-патогенных бактерий
                      </p>
                    </Box>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <StyledCircle>3</StyledCircle>
                      <p>
                      <span
                        style={{ color: '#F9D2D6' }}>Лактобактерии (90% в интимной зоне)вырабатывают молочнуюкислоту,</span>
                        тем самым создавая правильную кислую среду, которая является природным защитным барьером и
                        предотвращает дисбактериоз.
                      </p>
                    </Box>
                  </Box>
                  <img style={{ marginBottom: 5, maxWidth: '100%' }} src={lacImg1} alt="lactycid" />
                </StyledBoxShadow>

                <StyledBoxShadow>
                  <Box className="head" sx={{ marginTop: '20px' }}>
                    ЕЖЕДНЕВНЫЕ ФАКТОРЫ, ВЛИЯЮЩИЕ <br />
                    НА ЕСТЕСТВЕННУЮ МИКРОФЛОРУ*
                  </Box>

                  <Tables.WrapperTable sx={{ mt: 4, mb: 3 }}>
                    <Tables.Table className="align-left"
                                  sx={{
                                    fontSize: 14,
                                    minWidth: 900,
                                    lineHeight: '22px',
                                    tableLayout: 'fixed',
                                  }}>
                      <Tables.Thead>
                        <Tables.Tr sx={{ fontSize: 20, fontWeight: 'bold' }}>
                          <Tables.Td sx={{
                            color: Black,
                            backgroundColor: PinkLight,
                            verticalAlign: 'middle',
                            width: 300,
                          }}>Факторы</Tables.Td>
                          <Tables.Td sx={{ color: Black, backgroundColor: PinkLight }}>
                            Обусловленность влияния фактора на кислотно-щелочной баланс
                          </Tables.Td>
                        </Tables.Tr>
                      </Tables.Thead>
                      <Tables.Body>
                        <Tables.Tr>
                          <Tables.Td>Использование мыла или гелей для душа</Tables.Td>
                          <Tables.Td>Из-за содержания щелочи вызывают сухость, раздражение</Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr className="bg-pink20">
                          <Tables.Td>Интимная близость</Tables.Td>
                          <Tables.Td>
                            рН интимной зоны мужчины является более щелочным, поэтому половая близость
                            также влияет на изменение рН-баланса интимной микрофлоры женщины. Отсюда
                            возникает дискомфорт и нарушение естественного барьера
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td>Тесная или синтетическая одежда</Tables.Td>
                          <Tables.Td>
                            Нарушение микроциркуляции в области органов малого таза может привести
                            к снижению местного иммунитета
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr className="bg-pink20">
                          <Tables.Td>Занятие спортом</Tables.Td>
                          <Tables.Td>
                            Пот, который выделяется во время занятий спортом, содержит щелочную среду, что
                            приводит к изменению естественной микрофлоры и рН-баланса
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td>Плавание в бассейне</Tables.Td>
                          <Tables.Td>Разность с рН водой в бассейне способствует «выщелачиванию»</Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr className="bg-pink20">
                          <Tables.Td>
                            Прием антибиотиков / контрацептивов
                          </Tables.Td>
                          <Tables.Td>Ведет к снижению кислотности в интимной зоне</Tables.Td>
                        </Tables.Tr>
                      </Tables.Body>
                    </Tables.Table>
                  </Tables.WrapperTable>
                  <p style={{ fontSize: 12 }}>
                    *Chen et аl. Role of female intimate hygiene in vulvovaginal health: Global hygiene practices and
                    product usage. Women`s Health 2017, Vol. 1З(З) 58-671З
                  </p>
                </StyledBoxShadow>

                <StyledBoxShadow>
                  <Box className="head" sx={{ marginTop: '20px' }}>
                    ПЕРИОД ЖИЗНИ КАК ФАКТОР, ВЛИЯЮЩИЙ НА <br /> ЕСТЕСТВЕННУЮ МИКРОФЛОРУ
                  </Box>

                  <Tables.WrapperTable sx={{ mt: 4, mb: 3 }}>
                    <Tables.Table className="align-left"
                                  sx={{
                                    fontSize: 14,
                                    minWidth: 900,
                                    lineHeight: '22px',
                                    tableLayout: 'fixed',
                                  }}>
                      <Tables.Thead>
                        <Tables.Tr sx={{ fontSize: 20, fontWeight: 'bold' }}>
                          <Tables.Td sx={{
                            color: Black,
                            backgroundColor: PinkLight,
                            verticalAlign: 'middle',
                            width: 300,
                          }}>Факторы</Tables.Td>
                          <Tables.Td sx={{ color: Black, backgroundColor: PinkLight }}>
                            рН и микрофлора
                          </Tables.Td>
                        </Tables.Tr>
                      </Tables.Thead>
                      <Tables.Body>
                        <Tables.Tr>
                          <Tables.Td>Рождение</Tables.Td>
                          <Tables.Td>
                            <Box>
                              <span style={{ color: '#F9D2D6' }}>Кислый pH.</span> У новорожденной девочки вагина в
                              первые
                              часы жизн и стерильна и заполнена тягучей
                              слизью. По истечении двенадцати-четырнадцати часов влагалище заселяется молочнокислыми
                              бактериями (лактобациллами или палочками Додерлейна), перешедшими от матери. <br /> <br />
                              Они продолжают существовать на протяжении нескольких недель, пока микрофлора влагалища
                              остается кислой. В этот жизненный период микрофлора во влагалище новорожденной девочки
                              сходна с таковой у взрослой женщины.
                            </Box>
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr className="bg-pink20">
                          <Tables.Td>Детство</Tables.Td>
                          <Tables.Td>
                            После того, как из организма ребенка выводятся материнские гормоны, вагинальная среда
                            становится
                            нейтральной. Такое состояние сохраняется до периода полового созревания.
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td>Начало менструаций</Tables.Td>
                          <Tables.Td>
                            <span style={{ color: '#F9D2D6' }}>Средние значения рН 3.8 - 4.5.</span> Такие значения
                            считаются нормальными для женщин с активным
                            менструальным циклом. Они могут изменяться с установлением нормального овариального цикла.
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr className="bg-pink20">
                          <Tables.Td>Период
                            менструальных
                            циклов</Tables.Td>
                          <Tables.Td>
                            рН влагалища между менструациями составляет 4.4, в менструальный период — рН 6.5
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td>Беременность</Tables.Td>
                          <Tables.Td>
                            При нормальной беременности значение рН{'<'}4. При аномальной флоре и преждевременных
                            родах <span style={{ color: '#F9D2D6' }}>рН{'>'}4.2</span>
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr className="bg-pink20">
                          <Tables.Td>
                            Послеродовой период
                          </Tables.Td>
                          <Tables.Td>
                            Во влагалище преобладает щелочная реакция (выделения после родов имеют щелочную реакцию),
                            тогда как обычно она имеет кислую.
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td>
                            Климакс
                          </Tables.Td>
                          <Tables.Td>
                            <span style={{ color: '#F9D2D6' }}>рН 6.5.</span> С возрастом снижается содержание
                            лактобактерий
                            и повышается количество фекальных
                            бактерий.
                          </Tables.Td>
                        </Tables.Tr>
                      </Tables.Body>
                    </Tables.Table>
                  </Tables.WrapperTable>
                  <p style={{ fontSize: 12 }}>
                    *Chen et аl. Role of female intimate hygiene in vulvovaginal health: Global hygiene practices and
                    product usage. Women`s Health 2017, Vol. 1З(З) 58-671З
                  </p>
                </StyledBoxShadow>

                <StyledBoxShadow>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ПОСЛЕДСТВИЯ НАРУШЕНИЯ ЕСТЕСТВЕННОЙ МИКРОФЛОРЫ*
                  </Box>
                  <img src={lacImg2} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px', margin: '20px 0', flexWrap: 'wrap' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <StyledCircle>1</StyledCircle>
                      <p>
                        Нарушение естественной микрофлоры приводит к увеличению щелочности и, как следствие, <span
                        style={{ color: '#F9D2D6' }}>количество лактобактерийсокращается,</span> количество
                        условно-патогенных бактерий растёт
                      </p>
                    </Box>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <StyledCircle>2</StyledCircle>
                      <p>
                        Перевес «плохих» бактерий<span
                        style={{ color: '#F9D2D6' }}>приводит к дисбактериозу</span> (неправильному балансу бактерий) и,
                        как
                        следствие, к неприятному запаху, дискомфорту, раздражениям, зуду, восприимчивости к инфекциям.
                      </p>
                    </Box>
                  </Box>
                  <p style={{ fontSize: 12 }}>
                    *Chen et аl. Role of female intimate hygiene in vulvovaginal health: Global hygiene practices and
                    product usage. Women`s Health 2017, Vol. 1З(З) 58-671З
                  </p>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ПОДДЕРЖАНИЕ ЕСТЕСТВЕННОЙ МИКРОФЛОРЫ С ПОМОЩЬЮ LACTACYD
                  </Box>

                  <p>
                    Основной компонент — натуральная молочная кислота, входящая в состав Лактацида, обеспечивает кислую
                    среду интимной зоны, которая необходима для
                    поддержания естественной микрофлоры и профилактики дисбактериоза.
                  </p>
                  <img style={{ marginTop: 5 }} src={lacImg3} alt="lactacyd" />
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ЛИНИЯ СРЕДСТВ LACTACYD
                  </Box>

                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                    <Box sx={{ flex: '1 1 0' }}>
                      <img style={{ marginTop: 5 }} src={lacLine1} alt="lactacyd" />
                      <p style={{ color: '#F9D2D6' }}>
                        Натуральная молочная кислота
                        обеспечивает лечебно-профилактический эффект: восстанавливает и поддерживает
                        естественный рН и уровень
                        лактобактерий. Ограничивает рост условно-патогенных микроорганизмов, вызывающих
                        дрожжевые инфекции, вагиноз
                        и определенные типы вульвовагинита.
                      </p>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <img style={{ marginTop: 5 }} src={lacLine2} alt="lactacyd" />
                      <p style={{ color: '#F9D2D6' }}>
                        Натуральные активные компоненты, обладают терапевтическим деиствием и
                        помогают в разных ситуациях: повышенная
                        чувствительность, раздражение, сухость,
                        неприятный запах; зуд и жжение
                        при молочнице.
                      </p>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <img style={{ marginTop: 5 }} src={lacLine3} alt="lactacyd" />
                      <p style={{ color: '#F9D2D6' }}>
                        Отсутствие мыла в составе. Мягкие
                        моющие компоненты бережно
                        очищают кожу и слизистые половых
                        органов, удаляют раздражающие
                        выделения и патогенную микрофлору
                        в промежности.
                      </p>
                    </Box>
                  </Box>
                </StyledBoxShadow>


                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДЛЯ ЕЖЕДНЕВНОЙ ЗАБОТЫ ОБ ИНТИМНОЙ ЗОНЕ
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg4} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head">
                        ЛАКТАЦИД КЛАССИК
                        помогает поддерживать
                        микрофлору*
                      </Box>
                      <p style={{ lineHeight: '20px' }}>
                        Внешние факторы влияют на естественный баланс
                        в интимной зоне: использование для интимной гигиены мыла или гелей для душа, прокладки,
                        интимная близость, прием лекарств и другие.
                      </p>
                      <p style={{ color: '#F9D2D6', lineHeight: '20px' }}>
                        Лактацид Классик создан дnя ежедневной
                        интимной rиrиены. Гинекологические
                        тестирования подтвердили, что Lactacyd
                        поддерживает естественный рН-баланс и
                        естественную микрофлору*.
                      </p>
                      <p>Рекомендовано для ежедневного применения.</p>
                      <p style={{ fontSize: 18 }}><b>В составе:</b></p>
                      <UL sx={{ fontSize: 18 }}>
                        <LIW>
                          Натуральная молочная
                          кислота поддерживает рН
                          и микрофлору интимной
                          зоны
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          Мягкие моющие компоненты, специально
                          подобранные для
                          интимной гигиены
                        </LIW>
                      </UL>
                    </Box>
                    <Box sx={{ flex: '1 1 0' }}>
                      <Box className="border">
                        <img src={imgEveryday} alt="lactacyd" />
                      </Box>
                      <p style={{ fontSize: '12px', marginTop: '32px' }}>
                        *Study Report: LACТACYD in the treatment of bacterial vaginosis (nonspecific vaginitis). R.
                        Erkkola (Study No. FH 104) <br />
                        **№1 в России. По данным компании «IQVIA», январь-октябрь 2021 в категории «Средства дnя женской
                        интимной гигиены» в руб. и в уп.
                        №1 в мире. По данным компании «Nicolas Hall», 2019г. в категории «Средства дnя женской интимной
                        гигиены» в млн. евро.
                      </p>
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    LACTACYD PHARMA — СПЕЦИАЛЬНАЯ ЛИНИЯ СРЕДСТВ
                  </Box>

                  <Box sx={{
                    backgroundColor: '#F9D2D6',
                    color: '#000',
                    padding: '20px',
                    borderRadius: '12px',
                    marginBottom: '20px',
                  }}>
                    <b>LACTACYD PHARMA — </b>это специальная линия средств с <b>рН 3.5</b> для ежедневной интимной
                    гигиены
                    с содержанием натуральных активных компонентов,
                    обладающих терапевтической эффективностью. Помогает женщинам в различных ситуациях и в разный период
                    жизни.
                  </Box>

                  <Box className="border">
                    <ol style={{ margin: '0 0 0 20px', padding: 0 }}>
                      <li style={{ marginBottom: 12 }}>
                        Более низкий рН 3.5 способствующий абсорбции натуральной молочной кислоты для оптимального
                        стимулирования роста лактобактерий и лучшего терапевтического эффекта
                      </li>
                      <li style={{ marginBottom: 12 }}>В состав входят более эффективные компоненты с терапевтическим
                        действием
                      </li>
                      <li style={{ marginBottom: 12 }}>Восстанавливает и поддерживает рН и уровень лактобактерий</li>
                      <li style={{ marginBottom: 12 }}>
                        Ограничивает рост условно-патогенных организмов, вызывающих дрожжевые инфекции, вагиноз и
                        определенные типы вульвовагинита
                      </li>
                      <li style={{ marginBottom: 12 }}>
                        Больше исследований и доказательной базы
                      </li>
                      <li>
                        Исключение: Lactacyd Pharma Extra рН 8.0 создан специально для устранения дискомфорта во время
                        лечения молочницы
                      </li>
                    </ol>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    LACTACYD PHARMA
                  </Box>
                  <Box className="border">
                    Специальная линия средств, предназначенная для разных женских потребностей и
                    в разные периоды жизни. Обладает терапевтическим эффектом. Продается только
                    в аптеках.
                  </Box>

                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '20px' }}>
                    <Box className="border" sx={{ flex: '0 0 0 ', flexBasis: '30%' }} style={{ padding: '20px 8px' }}>
                      <Box className="badge">pH 3.5</Box>
                      <img style={{ padding: '16px 0', display: 'block', margin: 'auto' }} src={lacFarm1} />
                      <Box className="border-bottom border-top pink" sx={{ padding: '16px 0', fontSize: 18 }}>
                        для сверхчувствительной кожи
                      </Box>
                      <p style={{ color: '#E38696' }}>
                        <strong>LACTACYD PHARMA SENSITIVE</strong>
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        с натуральной молочной кислотой, без отдушек
                      </p>
                      <UL>
                        <LIW sx={{ marginBottom: '12px' }}>предотвращает раздражени</LIW>
                        <LIW>подходит в период полового созревания</LIW>
                      </UL>
                    </Box>

                    <Box className="border" sx={{ flex: '0 0 0 ', flexBasis: '30%' }} style={{ padding: '20px 8px' }}>
                      <Box className="badge">pH 3.5</Box>
                      <img style={{ padding: '16px 0', display: 'block', margin: 'auto' }} src={lacFarm2} />
                      <Box className="border-bottom border-top pink" sx={{ padding: '16px 0', fontSize: 18 }}>
                        антибактериальная защита во время беременности и после родов
                      </Box>
                      <p style={{ color: '#3CB5A3' }}>
                        <strong>LACTACYD PHARMA</strong>
                      </p>
                      <p>
                        с натуральной молочной кислотой, антибактериальными компонентами и экстрактом Тимьяна
                      </p>
                      <UL>
                        <LIW sx={{ marginBottom: '12px' }}>усиливает естественную защиту интимной
                          зоны</LIW>
                        <LIW>
                          особенно рекомендовано во время
                          беременности и после родов, во время
                          менструаций после занятий
                          спортом/посещения бассейна
                        </LIW>
                      </UL>
                    </Box>

                    <Box className="border" sx={{ flex: '0 0 0 ', flexBasis: '30%' }} style={{ padding: '20px 8px' }}>
                      <Box className="badge">pH 3.5</Box>
                      <img style={{ padding: '16px 0', display: 'block', margin: 'auto' }} src={lacFarm4} />
                      <Box className="border-bottom border-top pink" sx={{ padding: '16px 0', fontSize: 18 }}>
                        для увлажнения и предотвращения чувства сухости
                      </Box>
                      <p style={{ color: '#5DA2E7' }}>
                        <strong>LACTACYD PHARMA MOISTURIZING <br /> (УВЛАЖНЯЮЩИЙ)</strong>
                      </p>
                      <p>
                        с натуральной молочной кислотой и увлажняющим комплексом L2G-complex (глицерин + глицерил олеат)
                      </p>
                      <UL>
                        <LIW sx={{ marginBottom: '12px' }}>
                          продолжительное увлажнение и питание
                        </LIW>
                        <LIW sx={{ marginBottom: '12px' }}>
                          предотвращение сухости
                        </LIW>
                        <LIW>
                          особенно рекомендовано в период менопаузы
                        </LIW>
                      </UL>
                    </Box>

                    <Box className="border" sx={{ flex: '0 0 0 ', flexBasis: '30%' }} style={{ padding: '20px 8px' }}>
                      <Box className="badge">pH 3.5</Box>
                      <img style={{ padding: '16px 0', display: 'block', margin: 'auto' }} src={lacFarm3} />
                      <Box className="border-bottom border-top pink" sx={{ padding: '16px 0', fontSize: 18 }}>
                        для устранения дискомфорта: раздражения, зуда и покраснения
                      </Box>
                      <p style={{ color: '#9C9BD9' }}>
                        <strong>LACTACYD PHARMA SHOOTHING <br /> (СМЯГЧАЮЩИЙ)</strong>
                      </p>
                      <p>
                        с натуральной молочной кислотой, Алоэ Вера и экстрактом
                        Голубой Маргаритки
                      </p>
                      <UL>
                        <LIW sx={{ marginBottom: '12px' }}>
                          успокаивает, снижает раздражение,
                          покраснение и зуд
                        </LIW>
                        <LIW>
                          особенно рекомендовано при
                          лечении вагинальных инфекций
                        </LIW>
                      </UL>
                    </Box>


                    <Box className="border" sx={{ flex: '0 0 0 ', flexBasis: '30%' }} style={{ padding: '20px 8px' }}>
                      <Box className="badge">pH 8.0</Box>
                      <img style={{ padding: '16px 0', display: 'block', margin: 'auto' }} src={lacFarm5} />
                      <Box className="border-bottom border-top pink" sx={{ padding: '16px 0', fontSize: 18 }}>
                        для устранения дискомфорта при симптомах кандидоза (молочницы)
                      </Box>
                      <p style={{ color: '#D82C2D' }}>
                        <strong>LACTACYD PHARMA EXTRA</strong>
                      </p>
                      <p>
                        с противогрибковым компонентом, экстрактом Календулы и Бисабололом
                      </p>
                      <UL>
                        <LIW sx={{ marginBottom: '12px' }}>
                          ротивогрибковый компонент препятствует развитию Caпdida AIbicaпs — грибка, который вызывает
                          молочницу
                        </LIW>
                        <LIW>
                          мягкий щелочной рН8 ослабляет распространение дрожжевых организмов
                        </LIW>
                      </UL>
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДЛЯ ЖЕНЩИН С КОЖЕЙ, СКЛОННОЙ К РАЗДРАЖЕНИЮ
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg5} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head">
                        ЛАКТАЦИД ФАРМА СЕНСИТИВ помогает снизить риск раздражения кожи*
                      </Box>
                      <p style={{ lineHeight: '20px' }}>
                        Кожа половых органов тонкая и чувствительная и
                        демонстрирует преувеличенную реакцию на раздражители
                        по сравнению с другими областями тела. К таким
                        раздражителям относятся мыло или гели для душа из-за агрессивных компонентов и отдушек, входящих
                        в
                        состав.
                      </p>
                      <p style={{ color: '#F9D2D6', lineHeight: '20px' }}>
                        Лактацид Фарма Сенситив бережно очищает, при этом
                        защитные функции кожи и увлажненность после
                        применения средства остаются такими же, как после
                        очищения кожи водой*.
                      </p>
                      <p style={{ lineHeight: '20px' }}>
                        Кожа половых органов тонкая и чувствительная и
                        демонстрирует преувеличенную реакцию на раздражители
                        по сравнению с другими областями тела. К таким
                        раздражителям относятся мыло или гели для душа из-за агрессивных компонентов и отдушек, входящих
                        в
                        состав.
                      </p>
                      <p>Рекомендовано для ежедневного применения.</p>
                      <p style={{ fontSize: 18 }}><b>В составе:</b></p>
                      <UL sx={{ fontSize: 18, marginBottom: 0 }}>
                        <LIW>
                          активная (протонированная) форма молочной
                          кислоты, оказывающая подавляющее действие
                          на ряд вирусов и бактерий, снижая таким
                          образом риск урогенитальных инфекций.
                          Помогает восстанавливать и поддерживать
                          микрофлору и уровень рН.
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          рН 3.5 способствует ограничению роста
                          условно-патогенных организмов, вызывающих
                          бактериальный вагиноз и определенные типы
                          вульвовагинита**
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          Оптимальный состав ингредиентов, включая
                          мягкие очищающие компоненты
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          Без отдушек
                        </LIW>
                      </UL>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0', minWidth: '200px' }}>
                      <img src={imgSkin} />
                    </Box>
                    <Box sx={{ flex: '1 1 0', padding: '10px 0', fontSize: 12 }}>
                      *Источник:ТЕWL тест, май 2013, <br />
                      Швеция. **O'Hanlon DE, Соте RA, Moench TR. Vaginal рН measured in vivo: lactobacilli determine рН
                      and lactic
                      acid concentration. ВМС Mlcroblol. 2019;19(1):13. PuЬlished 2019 Jan 14. doi:1О. 1 18б/s12866-019-
                      1388-8.
                    </Box>
                  </Box>
                </StyledBoxShadow>


                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ПРИ СНИЖЕНИИ МЕСТНОГО ИММУНИТЕТА ИНТИМНОЙ ЗОНЫ
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg6} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head">
                        ЛАКТАЦИД ФАРМА с антибактериальными компонентами для снижения риска бактериальных вульвагинитов
                      </Box>
                      <p style={{ lineHeight: '20px' }}>
                        Для профилактики вагиноза и вульвовагинитов при снижении местного иммунитета интимной зоны,
                        обусловленном приемом лекарств, менструациями, беременностью и послеродовым периодом: Лактацид
                        Фарма с антибактериальными компонентами и с рН 3.5.
                      </p>
                      <p style={{ color: '#F9D2D6', lineHeight: '20px' }}>
                        Клинически доказано: Препятствует развитию патогенной микрофлоры!
                      </p>
                      <p style={{ lineHeight: '20px' }}>
                        Кожа половых органов тонкая и чувствительная и
                        демонстрирует преувеличенную реакцию на раздражители
                        по сравнению с другими областями тела. К таким
                        раздражителям относятся мыло или гели для душа из-за агрессивных компонентов и отдушек, входящих
                        в
                        состав.
                      </p>
                      <p>Рекомендовано для ежедневной интимной гигиены.</p>
                      <p style={{ fontSize: 18 }}><b>В составе:</b></p>
                      <UL sx={{ fontSize: 18, marginBottom: 0 }}>
                        <LIW sx={{ marginBottom: '12px' }}>
                          активная (протонированная) форма молочной
                          кислоты, оказывающая подавляющее действие на ряд
                          вирусов и бактерий, снижая таким образом риск
                          урогенитальных инфекций. Помогает восстанавливать
                          и поддерживать микрофлору и уровень рН
                        </LIW>
                        <LIW>
                          экстракт Тимьяна, обладающий противовоспалительными
                          и антисептическими свойствами
                        </LIW>
                      </UL>
                    </Box>
                  </Box>
                  <Box className="border" sx={{ width: 'fit-content', marginTop: '20px' }}>
                    <img src={imgImmun} />
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДЛЯ ЖЕНЩИН С ПОВЫШЕННОЙ СУХОСТЬЮ В ИНТИМНОЙ ЗОНЕ
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg7} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head">
                        ЛАКТАЦИД ФАРМА УВЛАЖНЯЮЩЕЕ
                      </Box>
                      <p style={{ lineHeight: '20px' }}>
                      <span style={{ color: '#F9D2D6' }}>Через 3 часа после интимной
                      гигиены с применением
                      средства Лактацид Фарма
                      Увлажняющее увлажненность
                      кожи была на 41 % выше, чем
                      без применения*.</span> В период менопаузы кожа в интимной зоне
                        становится суше и тоньше, повышается уровень рН,
                        что приводит к ощущению сухости и дискомфорту.
                        Для увлажнения и предотвращения чувства
                        сухости создано средство для ежедневной
                        интимной rиrиены Лактацид Фарма
                        Увлажняющее с рН 3.5.
                        Средство Лактацид Фарма Увлажняющее
                        восстанавливает гидролипидный слой и помогает дополнительно питать кожу в интимной зоне*.
                      </p>
                      <p>Рекомендовано для ежедневного применения.</p>
                      <p style={{ fontSize: 18 }}><b>В составе:</b></p>
                      <UL sx={{ fontSize: 18, mb: 0 }}>
                        <LIW>
                          активная (протонированная) форма молочной
                          кислоты, оказывающая подавляющее действие на
                          ряд вирусов и бактерий, снижая таким образом риск
                          урогенитальных инфекций. Помогает восстанавливать
                          и поддерживать микрофлору и уровень рН.
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          увлажняющий L2G-комплекс (глицерин, глицерил
                          олеат). Средство восстанавливает гидролипидный
                          слой и помогает дополнительно питать кожу в
                          интимной зоне.
                        </LIW>
                      </UL>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0', minWidth: '200px' }}>
                      <img src={imgDry} />
                    </Box>
                    <Box sx={{ flex: '1 1 0', padding: '10px 0', fontSize: 12 }}>
                      *Источник: Корнеометрия in vivo, 12 участников - июнь 201З r. <br />
                      **№1 в России. По данным компании “IQVIA”, январь-октябрь 2021 в категории “Средства для женской
                      интимной гигиены” в руб. и в уп.
                      №1 в мире. По данным компании “Nicolas Hall”, 2019 r. в категории “Средства для женской интимной
                      гигиены” в млн. евро.
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДЛЯ ЖЕНЩИН С БАКТЕРИАЛЬНЫМ ВАГИНОЗОМ
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg8} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head">
                        ЛАКТАЦИД ФАРМА СМЯГЧАЮЩИЙ: до -85% уменьшение дискомфорта**
                      </Box>
                      <p style={{ lineHeight: '20px' }}>
                        Бактериальный вагиноз часто сопровождается
                        вагинальным дискомфортом.
                      </p>

                      <p style={{ lineHeight: '20px', color: '#F9D2D6' }}>
                        Помочь снизить ощущения вагинального
                        дискомфорта: зуда, раздражения,
                        жжения может Лактацид Фарма
                        Смягчающий для ежедневной интимной
                        rигиены с рН 3.5. <br />
                        Средство рекомендовано использовать
                        в комплексном лечении ваrинальных
                        инфекций*.
                      </p>
                      <p>Рекомендовано для ежедневного применения.</p>
                      <p style={{ fontSize: 18 }}><b>В составе:</b></p>
                      <UL sx={{ fontSize: 18, mb: 0 }}>
                        <LIW>
                          активная (протонированная) форма молочной кислоты,
                          оказывающая подавляющее действие на ряд вирусов и
                          бактерий, снижая таким образом риск урогенитальных
                          и нфекций. Помогает восстанавливать и поддерживать
                          микрофлору и уровень рН.
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          экстракт Голубой маргаритки и Алоэ Вера, обладающие
                          противовоспалительными и успокаивающими свойствами,
                          которые помогают снизить ощущения вагинального
                          дискомфорта: раздражения, зуда и покраснений.
                        </LIW>
                      </UL>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0', minWidth: '200px' }}>
                      <img src={imgBactery} />
                    </Box>
                    <Box sx={{ flex: '1 1 0', padding: '10px 0', fontSize: 12 }}>
                      *Иследование TEWL in vivo, - май 201З r. Исследовательский институт, Стокrольм,
                      Швеция. <br /> **A.О.G.O.1.
                      Giornale ltaliano di Ostetricia е Ginecologia (ltalian Obstetrics and Gynaecology Journal) 1988,
                      no.7: 528- (Study No. FH 102) <br /> ***№1 в России. По данным компании «IQVIA», январь-октябрь
                      2021
                      в
                      категории «Средства для женской интимной гигиены» в руб. и в уп.
                      №1 в мире. По данным компании «Nicolas Hall», 2019 г. в категории «Средства для женской интимной
                      гигиены» в млн. евро.
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДЛЯ ПОВЫШЕНИЯ ЭФФЕКТИВНОСТИ СТАНДАРТНОЙ ТЕРАПИИ МОЛОЧНИЦЫ***
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg9} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head">
                        ЛАКТАЦИД
                        ФАРМА ЭКСТРА: Даже при разбавлении
                        средства до 5% происходит
                        подавление роста грибов
                        Candida и Aspergillus****
                      </Box>
                      <p style={{ lineHeight: '20px' }}>
                        Кандидоз сопровождается
                        неприятными ощущениями
                        в виде выделений, зуда, жжения
                        и раздражения.
                      </p>

                      <p style={{ lineHeight: '20px', color: '#F9D2D6' }}>
                        Добавление Лактацид Фарма
                        Экстра к стандартной терапии
                        вульвогинального кандидоза
                        было более эффективно, чем
                        стандартная терапия, в 87,5%***.
                        Усиливается противогрибковый
                        эффект антимикотиков.
                      </p>
                      <p>Рекомендовано в период лечения
                        кандидоза, а также профилактики.</p>
                      <p style={{ fontSize: 18 }}><b>В составе:</b></p>
                      <UL sx={{ fontSize: 18, mb: 0 }}>
                        <LIW>
                          Противогрибковый компонент феноксиэтанол — органическое соединение,
                          содержится в зеленом чае, грейпфруте и других растениях. Механизм
                          противогрибкового действия основан на повышении проницаемости клеточной
                          мембраны для ионов калия. При этом феноксиэтанол не оказывает вредное
                          воздействие на полезную микрофлору.
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          Мягкий щелочной рН 8 снижает риск развития выраженного
                          воспаления при кандидозе*.
                          В исследовании С. alЬicans в кислой среде вызывали гораздо более сильный
                          противоспалительный ответ, чем клетки С. alЬicans в более щелочных условиях*. Кроме того, в
                          щелоч ной среде выше восприимчивость С. alЬicans
                          к наиболее распространенным противогрибковым препаратам**.
                        </LIW>
                        <LIW sx={{ marginTop: '12px' }}>
                          Экстракт Календулы и Бисаболола смягчают и успокаивают.
                        </LIW>
                      </UL>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '20px' }}>
                    <Box className="border" sx={{ flex: '1 1 0', minWidth: '200px' }}>
                      <img src={imgStandart} />
                    </Box>
                    <Box sx={{ flex: '1 1 0', padding: '10px 0', fontSize: 12 }}>
                      {'*Sherrington SL, et al. (2017) Adaptation of Candida alЫcans to environmental рН induces cell wall remodelling and enhances innate immune recognition.\n' +
                        '                    PLoS Pathog 13(5): е1006403.'} <br />
                      {'**Liu W, et al. lmpact of рН on the antifungal susceptiЫlity of vagina l Candida alЫcans. lnt J Gynaecol Obstet. 2011\n' +
                        '                    5е р; 114{3):278-80.'} <br />
                      {'***Исследование эффективности и приемлемости средства для интимной гигиены Lactacyd Pharma Extra с противоrрибковым\n' +
                        '                    компонентом в лечении кандидоза в 5 Молодежных консультациях г. Санкт-Петербурга.'}
                      <br />
                      {' ****Протокол исnытаний «Фунгистатическая активность\n' +
                        '                    ЛАКТАЦИД «Алкалайн» для интимной гигиены с противогрибковыми компонентами» (DGHM, 200Н)9-01; 7); г. Гамбург, 18.08.2014.'}
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДЛЯ ПОВЫШЕНИЯ ЭФФЕКТИВНОСТИ СТАНДАРТНОЙ ТЕРАПИИ МОЛОЧНИЦЫ***
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg10} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head" style={{ fontSize: '24px' }}>
                        Бережное очищение и уход:
                      </Box>
                      <UL>
                        <LI sx={{ mb: '12px' }}>
                          Поддержание естественной микрофлоры за счет натуральной
                          молочной кислоты
                        </LI>
                        <LI sx={{ mb: '12px' }}>
                          Очищение без раздражения с эффектом шелка за счет мягкого
                          лосьона и вискозного волокна
                        </LI>
                        <LI sx={{ mb: '12px' }}>LACTACYD CLASSIC для очищения и поддержания микрофлоры</LI>
                        <LI>
                          LACTACYD PHARMA с антибактериальной защитой за счет
                          экстракта Тимьяна
                        </LI>
                      </UL>
                    </Box>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head" style={{ fontSize: '24px' }}>
                        БЕЗОПАСНОСТЬ
                      </Box>
                      <UL>
                        <LI sx={{ mb: '12px' }}>
                          Гипоаллергенны
                        </LI>
                        <LI sx={{ mb: '12px' }}>
                          Одобрены Российским обществом
                          акушеров-гинекологов
                        </LI>
                        <LI>Без парабенов</LI>
                      </UL>
                    </Box>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <Box className="sub-head" style={{ fontSize: '24px' }}>
                        УДОБСТВО И КОМФОРТ
                      </Box>
                      <UL>
                        <LI sx={{ mb: '12px' }}>
                          Мини-упаковка 8 шт. поместится
                          даже в карман джинсов
                        </LI>
                        <LI sx={{ mb: '12px' }}>
                          Оптимальный размер салфетки — 14 х 15 см
                        </LI>
                        <LI>
                          Влажные салфетки до последней
                          штуки — за счет закрывающегося
                          клапана</LI>
                      </UL>
                    </Box>
                  </Box>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДЛЯ ЗАЩИТЫ ОТ ЗАПАХА — ДО 12-ТИ ЧАСОВ СВЕЖЕСТИ*
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg11} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                    <Box className="border" sx={{
                      flex: '1 1 0',
                      backgroundColor: '#F9D2D6',
                      color: '#000',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                      <Box className="sub-head" style={{ fontSize: '24px', color: '#2F2F34', fontWeight: 'bold' }}>
                        LACTACYD OXYGEN FRESH
                      </Box>
                      <p style={{ fontSize: 24, margin: '12px 0' }}>
                        Ультраосвежающее средство
                        с натуральной молочной кислотой
                        и ягодами Арктической
                        княженики
                      </p>
                    </Box>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <UL sx={{ mt: 0, mb: 0 }}>
                        <LI sx={{ mb: '12px' }}>
                          натуральная молочная кислота
                          поддерживает микрофлору интимной
                          зоны
                        </LI>
                        <LI sx={{ mb: '12px' }}>
                          обогащено ягодами Арктической
                          княженики
                        </LI>
                        <LI sx={{ mb: '12px' }}>
                          уникальная консистенция**
                          с кислородными пузырьками
                        </LI>
                        <LI>
                          моментальная свежесть и
                          длительное ощущение комфорта
                        </LI>
                      </UL>
                    </Box>
                  </Box>
                  <p style={{ fontSize: '12px', marginTop: '24px' }}>
                    *поданным исследовательсткого центра «ДЕРМАСКАН ПОЛАНД» (DERMASCAN POLAND), 15 июля 2016 г. **в
                    линии
                    Лактацид
                  </p>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    ДО 8-МИ ЧАСОВ УВЛАЖНЕНИЯ И МЯГКОСТИ*
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg12} alt="lactacyd" />

                  <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                    <Box className="border" sx={{
                      flex: '1 1 0',
                      backgroundColor: '#F9D2D6',
                      color: '#000',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}>
                      <Box className="sub-head" style={{ fontSize: '24px', color: '#2F2F34', fontWeight: 'bold' }}>
                        LACTACYD <br />Премиальное масло
                      </Box>
                      <p style={{ fontSize: 24 }}>
                        С натуральной молочной кислотой
                        и природными маслами
                      </p>
                    </Box>
                    <Box className="border" sx={{ flex: '1 1 0' }}>
                      <UL sx={{ mt: 0, mb: 0 }}>
                        <LI sx={{ mb: '12px' }}>
                          нежная формула с природными
                          маслами (семян подсолнечника
                          и соевого), не жирная, легко смывается
                        </LI>
                        <LI sx={{ mb: '12px' }}>
                          натуральная молочная кислота
                          поддерживает микрофлору
                          интимнои зоны
                        </LI>
                        <LI sx={{ mb: '12px' }}>
                          ультрамягкое
                          и деликатное очищение
                          до 8 часов поддерживает
                          увлажнение и мягкость
                        </LI>
                        <LI>
                          приятный аромат
                        </LI>
                      </UL>
                    </Box>
                  </Box>
                  <p style={{ fontSize: '12px', marginTop: '24px' }}>
                    *у 85% участниц исследования. Отчет № 16ЕО783, 15 июля 2016 r. исследовательского центра Dermscan
                    Poland <br />*Клиническая оценка переносимости и эффективности косметического средства «Лактацид
                    моющее
                    масло».
                  </p>
                </StyledBoxShadow>

                <StyledBoxShadow sx={{ paddingBottom: '30px' }}>
                  <Box className="head" sx={{ margin: '20px 0' }}>
                    Женское здоровье вместе с LACTACYD®
                  </Box>
                  <img style={{ marginTop: 5, marginBottom: 20 }} src={lacImg13} alt="lactacyd" />

                  <Box className="border">
                    <p style={{ color: '#FFF', fontSize: 16, marginTop: 0 }}>Мария Горбань, актриса театра и кино:</p>
                    <p style={{ color: '#F9D2D6', margin: 0 }}>
                      «Женское здоровье — это самое ценное,
                      что есть у каждой представительницы
                      прекрасной половины человечества.
                      Именно этот продукт незаменим в ванной
                      комнате каждой современной девушки»
                    </p>
                  </Box>
                </StyledBoxShadow>

              </Content>
            </Fade>
          )
        }

        <Popup
          opened={isOpen}
          togglePopup={() => setIsOpen(false)}
          title="Материалы"
        >
          <PrezentationItem name="Визиты 2025: Январь-Февраль" pdfName="sensitive" type="Материалы" />
          <PrezentationItem name="Визиты 2025: Март" pdfName="antibacterial" type="Материалы" />
          <PrezentationItem name="Визиты 2025: Апрель" pdfName="extra" type="Материалы" />
          <PrezentationItem name="Визиты 2025: Май" pdfName="moisturizing" type="Материалы" />
          <PrezentationItem name="Визиты 2025: Июнь" pdfName="softening" type="Материалы" />
          <PrezentationItem name="Визиты 2025: Июль-Август" pdfName="classic" type="Материалы" />
          <PrezentationItem name="Lactacyd Pharma Soothing при бактериальном вагинозе"
                            pdfName="Lactacyd_Vrach_Soothing_Booklet_A4_04" type="Материалы" />
          <PopupItemWrap onClick={handleRedirect}>
            <img src={file} alt="*" />
            <span style={{ marginLeft: '10px' }}>LACTACYD PHARMA c антибактериальными компонентами</span>
          </PopupItemWrap>
        </Popup>

      </Page>
    </>
  );
};
